export const relief1 = [
    './assets/1/1.jpeg',
    './assets/1/2.jpeg',
    './assets/1/3.jpg',
    './assets/1/4.jpg',
    './assets/1/5.jpg',
    './assets/1/6.jpg',
    './assets/1/7.jpg',
    './assets/1/8.jpg',
    './assets/1/9.jpg',
    './assets/1/10.jpg',
    './assets/1/11.jpg',
    './assets/1/12.jpg',
    './assets/1/13.jpg',
    './assets/1/14.jpg',
    './assets/1/15.jpg',
    './assets/1/16.jpeg',
    './assets/1/17.jpeg',
    './assets/1/18.jpeg',
    './assets/1/19.jpeg',
    './assets/1/20.jpeg',
    './assets/1/21.jpeg'
]

export const relief2 = [
    './assets/2/1.jpg',
    './assets/2/2.jpg',
    './assets/2/3.jpg',
    './assets/2/4.jpg',
    './assets/2/5.jpg',
    './assets/2/6.jpg',
    './assets/2/7.jpg',
    './assets/2/8.jpg',
    './assets/2/9.jpg',
    './assets/2/10.jpg',
    './assets/2/11.jpg',
    './assets/2/12.jpg',
    './assets/2/13.jpg',
    './assets/2/14.jpg',
    './assets/2/15.jpg',
    './assets/2/16.jpg',
    './assets/2/17.jpg',
    './assets/2/18.jpg',
    './assets/2/19.jpg',
    './assets/2/20.jpg',
    './assets/2/21.jpg',
    './assets/2/22.jpg',
    './assets/2/23.jpg',
    './assets/2/24.jpg'
]
export const relief3 = [
    './assets/3/1.jpg',
    './assets/3/2.jpg',
    './assets/3/3.jpg',
    './assets/3/4.jpg',
    './assets/3/5.jpg',
    './assets/3/6.jpg',
    './assets/3/7.jpg',
    './assets/3/8.jpg',
    './assets/3/9.jpg',
    './assets/3/10.jpg',
    './assets/3/11.jpg',
    './assets/3/12.jpg',
    './assets/3/13.jpg',
    './assets/3/14.jpg',
    './assets/3/15.jpg',
    './assets/3/16.jpg',
    './assets/3/17.jpg',
    './assets/3/18.jpg',
    './assets/3/19.jpg',
    './assets/3/21.jpg',
    './assets/3/22.jpg',
    './assets/3/23.jpg',
    './assets/3/24.jpg',
]
export const relief5 = [
    './assets/5/1.jpeg',
    './assets/5/2.jpeg',
    './assets/5/3.jpeg',
    './assets/5/4.jpeg',
    './assets/5/5.jpeg',
    './assets/5/6.jpeg',
    './assets/5/7.jpeg',
    './assets/5/8.jpeg',
    './assets/5/9.jpeg',
    './assets/5/10.jpeg',
    './assets/5/11.jpeg',
    './assets/5/12.jpeg',
    './assets/5/13.jpeg',
    './assets/5/14.jpeg',
    './assets/5/15.jpeg',
    './assets/5/16.jpeg',
    './assets/5/17.jpeg',
    './assets/5/18.jpeg',
    './assets/5/19.jpeg',
    './assets/5/20.jpeg',
    './assets/5/21.jpeg'
]


export const relief4 = [
    './assets/4/0.jpg',
    './assets/4/1.jpg',
    './assets/4/2.jpg',
    './assets/4/3.jpg',
    './assets/4/4.jpg',
    './assets/4/5.jpg',
    './assets/4/6.jpg',
    './assets/4/7.jpg',
    './assets/4/8.jpg',
    './assets/4/9.jpg',
    './assets/4/10.jpg',
    './assets/4/11.jpg',
    './assets/4/12.jpg',
    './assets/4/13.jpg',
    './assets/4/14.jpg',
    './assets/4/15.jpg',
    './assets/4/16.jpg',
    './assets/4/17.jpg',
    './assets/4/18.jpg',
    './assets/4/19.jpg',
    './assets/4/20.jpg',
    './assets/4/21.jpg',
    './assets/4/22.jpg',
    './assets/4/23.jpg',
    './assets/4/24.jpg',
    './assets/4/25.jpg',
    './assets/4/26.jpg',
    './assets/4/27.jpg',
    './assets/4/28.jpg',
    './assets/4/29.jpg',
]
export const relief6 = [
    './assets/6/1-min.jpeg',
    './assets/6/2-min.jpeg',
    './assets/6/3-min.jpeg',
    './assets/6/4-min.jpeg',
    './assets/6/5-min.jpeg',
    './assets/6/6-min.jpeg',
    './assets/6/7-min.jpeg',
    './assets/6/8-min.jpeg',
    './assets/6/9-min.JPG',
    './assets/6/10-min.JPG',
    './assets/6/11-min.jpeg',
    './assets/6/12-min.jpeg',
    './assets/6/13-min.jpeg',
    './assets/6/14-min.jpeg',
    './assets/6/15-min.jpeg',
    './assets/6/16-min.jpeg',
    './assets/6/17-min.jpeg',
    './assets/6/18-min.JPG',
] 
export const relief8 = [
    './assets/8/1-min.jpg',
    './assets/8/2-min.jpg',
    './assets/8/3-min.jpg',
    './assets/8/4-min.jpg',
    './assets/8/5-min.jpg',
    './assets/8/6-min.jpg',
    './assets/8/7-min.jpg',
    './assets/8/8-min.jpg',
    './assets/8/9-min.jpg',
    './assets/8/10-min.jpg',
    './assets/8/12-min.jpg',
    './assets/8/13-min.jpg',
    './assets/8/14-min.jpg',
    './assets/8/15-min.jpg',
    './assets/8/16-min.jpg',
    './assets/8/17-min.jpg',
    './assets/8/19-min.jpg',
    './assets/8/20-min.jpg',
]

export const relief7 = [
    './assets/7/1-min.jpg',
    './assets/7/3-min.jpg',
    './assets/7/4-min.jpg',
    './assets/7/5-min.jpg',
    './assets/7/6-min.jpg',
    './assets/7/7-min.jpg',
    './assets/7/9-min.jpg',
    './assets/7/10-min.jpg',
    './assets/7/11-min.jpg',
    './assets/7/12-min.jpg',
    './assets/7/14-min.jpg',
    './assets/7/15-min.jpg',
    './assets/7/16-min.jpg',
    './assets/7/17-min.jpg',
    './assets/7/18-min.jpg',
    './assets/7/19-min.jpg',
    './assets/7/20-min.jpg',
    './assets/7/21-min.jpg'
]